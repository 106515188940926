import * as React from 'react';

const Faqs = () => 
    <div className="main-content">
        <section data-pg-name="Features" className="section-fade-in section-space-print section-spacer">
            <div className="container">
                <div className="justify-content-center row">
                    <div className="col-11 col-lg-12 fade-in">
                        <p id="breadcrumbs"><span><span><a href="/">Home</a></span> <span className="yoast-sep mx-2"><svg xmlns="http://www.w3.org/2000/svg" width="10.438" height="9.409" viewBox="0 0 10.438 9.409">
                            <g id="Group_2183" data-name="Group 2183" transform="translate(-141.201 -213.442)">
                                <line id="Line_11" data-name="Line 11" x2="8.629" transform="translate(141.951 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_12" data-name="Line 12" x2="3.19" y2="3.646" transform="translate(147.39 214.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_13" data-name="Line 13" y1="3.646" x2="3.19" transform="translate(147.39 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                            </g>
                        </svg></span> <span className="breadcrumb_last" aria-current="page">FAQs</span></span></p>
                        <p className="display-6 fw-bold mb-5">Frequently Asked Questions</p>
                    </div>
                </div>
                <div className="justify-content-center justify-content-lg-between row">
                    <div className="col-11 col-lg-4 mb-5">
                        <nav id="navbar-example3" className="border-end pe-4 position-sticky sticky-faq sticky-top">
                            <h4 className="mb-3">Categories</h4>
                            <nav className="flex-column nav nav-pills"><a className="nav-link" href="#faqgroup1">YourVue App</a><a className="nav-link" href="#faqgroup2">YourVue Panel Membership</a><a className="nav-link" href="#faqgroup3">YourVue Surveys</a>
                                <a className="nav-link" href="#faqgroup4">Rewards and Payments</a>
                                <a className="nav-link" href="#faqgroup5">Privacy and Security</a>
                            </nav>
                        </nav>
                    </div>
                    <div className="col-11 col-lg-8">
                        <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-smooth-scroll="true" className="scrollspy-example-2" tabIndex={0}>
                            <div id="faqgroup1" className="faq-group">
                                <h4>YourVue App</h4>
                                <p>Get the YourVue app on your compatible mobile device</p>
                                <div className="accordion accordion-flush faq-accordian mb-5" id="app">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAppOne" aria-expanded="false" aria-controls="collapseAppOne">What are the minimum requirements to get YourVue on my smartphone or tablet?</button> </h2>
                                        <div id="collapseAppOne" className="accordion-collapse collapse" data-bs-parent-removed="#app">
                                            <div className="accordion-body">
                                                <p>The YourVue app is compatible with most smartphones and tablets. Apple devices require iOS/iPadOS 15.0 or later, and Android devices require Android 7 and above.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAppThree" aria-expanded="false" aria-controls="collapseAppThree">Is the YourVue app really free?</button> </h2>
                                        <div id="collapseAppThree" className="accordion-collapse collapse" data-bs-parent-removed="#app">
                                            <div className="accordion-body">
                                                <p>Yes! The YourVue app is absolutely, completely and utterly, FREE to download and use. There are no in-app purchases, subscription charges, or any costs whatsoever &ndash; in fact, YourVue PAYS YOU! The more surveys you complete with YourVue, the more cash you&rsquo;ll earn.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAppFour" aria-expanded="false" aria-controls="collapseAppFour">What if I lose or upgrade my device?</button> </h2>
                                        <div id="collapseAppFour" className="accordion-collapse collapse" data-bs-parent-removed="#app">
                                            <div className="accordion-body">
                                                <p>Simply install the YourVue app on your new device and login with the same email and password, or sign-in with Google or Apple, as before. Your profile and previous balance will be displayed, so you can easily pick up where you left off. If your previous device was lost or stolen, and you&rsquo;re concerned that someone else could access your apps, you can request a new password for YourVue when you first open the app on your new device.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAppFive" aria-expanded="false" aria-controls="collapseAppFive">Can I install YourVue on my smartphone AND my tablet? </button> </h2>
                                        <div id="collapseAppFive" className="accordion-collapse collapse" data-bs-parent-removed="#app">
                                            <div className="accordion-body">
                                                <p>Yes, provided you only create one YourVue account, you can sign in using the same login credentials on each of your devices. Please don&rsquo;t use YourVue on multiple devices at the same time (even if you&rsquo;re great at multi-tasking!) as this could trigger a security block on your account.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="faqgroup2" className="faq-group">
                                <h4>YourVue Panel Membership</h4>
                                <p>YourVue membership is available exclusively via the YourVue app</p>
                                <div className="accordion accordion-flush faq-accordian mb-5" id="membership">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMemberOne" aria-expanded="false" aria-controls="collapseMemberOne">Who can join YourVue?</button> </h2>
                                        <div id="collapseMemberOne" className="accordion-collapse collapse" data-bs-parent-removed="#membership">
                                            <div className="accordion-body">
                                                <p>YourVue is available to all UK residents, aged 16 and over. You&rsquo;ll need access to the internet, with a compatible mobile device that you own, or where you have permission to install and use the YourVue app. To cash out rewards to PayPal, you&rsquo;ll need a PayPal account that can receive payments.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMemberTwo" aria-expanded="false" aria-controls="collapseMemberTwo">Can I create an extra YourVue account on my other device to earn more rewards?</button> </h2>
                                        <div id="collapseMemberTwo" className="accordion-collapse collapse" data-bs-parent-removed="#membership">
                                            <div className="accordion-body">
                                                <p>No. To protect the validity of our research data we only allow one account per person at any one time. We trust that you understand the reasons why this is important to our clients. YourVue aims to provide lots of interesting and engaging surveys for every YourVue panel member to earn rewards via the YourVue app. In return, we expect that each YourVue member is an individual, real human being (no robots!), aged 16 or over, residing in the UK. If our systems detect that you have more than one active account, used on multiple devices, or the same device, all the accounts may be blocked for security.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMemberThree" aria-expanded="false" aria-controls="collapseMemberThree">Is YourVue a scam, how can I check if YourVue is legit?</button> </h2>
                                        <div id="collapseMemberThree" className="accordion-collapse collapse" data-bs-parent-removed="#membership">
                                            <div className="accordion-body">
                                                <p>YourVue is a legitimate market research access panel available exclusively via the YourVue mobile app. YourVue is owned and operated by <a href="https://savanta.com/about/">Savanta Group Limited</a> (company no: SC 281 352), a data and market research company. Savanta is a company partner of the <a href="https://www.mrs.org.uk/">Market Research Society (MRS)</a>, compliant with the <a href="https://www.mrs.org.uk/standards/code-of-conduct">MRS Code of Conduct</a>, which sets the market research industry regulations and professional standards.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="faqgroup3" className="faq-group">
                                <h4>YourVue Surveys</h4>
                                <p>Personalised surveys on your mobile, complete surveys on-the-go, anywhere you connect to WiFi or your cellular data network</p>
                                <div className="accordion accordion-flush faq-accordian mb-5" id="surveys">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesurveysOne" aria-expanded="false" aria-controls="collapsesurveysOne">Will I get screened out of YourVue surveys?</button> </h2>
                                        <div id="collapsesurveysOne" className="accordion-collapse collapse" data-bs-parent-removed="#surveys">
                                            <div className="accordion-body">
                                                <p>We hate screen-outs, so YourVue is designed to use your profile information to only show personalised surveys that are relevant to you and prevent screen-outs on profile data. You may occasionally be invited to complete a survey that requires a very specific target audience, and you could experience a screen out if you don&rsquo;t meet the extra criteria that are not part of your basic profile. YourVue always asks these extra selection questions at the beginning of the survey to avoid wasting your time if the survey isn&rsquo;t a match for you.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesurveysTwo" aria-expanded="false" aria-controls="collapsesurveysTwo">Do I have to answer profile questions every time I take a survey?</button> </h2>
                                        <div id="collapsesurveysTwo" className="accordion-collapse collapse" data-bs-parent-removed="#surveys">
                                            <div className="accordion-body">
                                                <p>YourVue won&rsquo;t bore you by asking the same basic questions over and over. When you sign up to YourVue, you&rsquo;ll be invited to complete a short profile survey about yourself. YourVue uses your profile answers to select personalised surveys just for you, and these answers are pre-completed when you enter the survey. Sometimes, you&rsquo;ll be asked to confirm a couple of profile points, such as the region where you live, or your age in years &ndash; things that may have changed since you completed your profile survey, but you won&rsquo;t be asked these questions every time.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesurveysThree" aria-expanded="false" aria-controls="collapsesurveysThree">What kind of surveys does YourVue offer?</button> </h2>
                                        <div id="collapsesurveysThree" className="accordion-collapse collapse" data-bs-parent-removed="#surveys">
                                            <div className="accordion-body">
                                                <p>YourVue surveys are mainly online market research surveys covering a wide variety of interesting topics. You may also be invited to participate in other market research activities, such as telephone surveys, product tests, or focus groups. If you&rsquo;re selected to participate in other types of research, you will be invited to complete a short recruitment survey in the YourVue app, where you will be given full details of the study requirements and the rewards offered. If you don&rsquo;t wish to participate, you are free decline any research study or survey without giving a reason.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesurveysFour" aria-expanded="false" aria-controls="collapsesurveysFour">Do I need to pay for using the YourVue app?</button> </h2>
                                        <div id="collapsesurveysFour" className="accordion-collapse collapse" data-bs-parent-removed="#surveys">
                                            <div className="accordion-body">
                                                <p>YourVue membership is free and the YourVue app is free to download. YourVue online surveys are accessible only through the YourVue app, on your smartphone or tablet, which requires an internet connection. You are responsible for any costs incurred for using Wi-Fi, and for your home broadband connection. When completing surveys on-the-go, check your mobile data plan with your network provider to avoid any charges for exceeding your data allowance.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="faqgroup4" className="faq-group">
                                <h4>Rewards and Payments</h4>
                                <p>Earn incentive points for each survey you complete, track your balance in the app, cash out your rewards to PayPal, or exchange your points for a Gift Card with Love2Shop</p>
                                <div className="accordion accordion-flush faq-accordian mb-5" id="rewards">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapserewardsOne" aria-expanded="false" aria-controls="collapserewardsOne">How much do YourVue survey pay?</button> </h2>
                                        <div id="collapserewardsOne" className="accordion-collapse collapse" data-bs-parent-removed="#rewards">
                                            <div className="accordion-body">
                                                <p>YourVue pays you incentive points for every survey you complete. Most survey incentives range from 10 points up to 200 points, and the amount of incentive points offered for each survey depends on how long it should take to complete. A quick 1&ndash;2-minute survey will pay 10 points (worth 10p), whereas a longer, more detailed survey may be worth 50 points (50p) or 100 points (&pound;1).</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapserewardsTwo" aria-expanded="false" aria-controls="collapserewardsTwo">How much can I earn with YourVue?</button> </h2>
                                        <div id="collapserewardsTwo" className="accordion-collapse collapse" data-bs-parent-removed="#rewards">
                                            <div className="accordion-body">
                                                <p>This depends how many surveys you want to complete. You might choose to take a few longer surveys to bank bigger rewards, or lots of shorter surveys to rack up rewards quickly &ndash; you do you with YourVue! Track of your rewards balance in the YourVue app as you earn incentive points for each survey completed. The cash-out threshold is just &pound;5, so you can exchange your points for a Love2Shop Gift Card, or cash out via PayPal, and start spending your rewards straight away!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapserewardsThree" aria-expanded="false" aria-controls="collapserewardsThree">How do I cash out rewards?</button> </h2>
                                        <div id="collapserewardsThree" className="accordion-collapse collapse" data-bs-parent-removed="#rewards">
                                            <div className="accordion-body">
                                                <p>Whenever your rewards balance reaches &pound;5 (500 points) or more, simply click &ldquo;Cash out to PayPal&rdquo; or &ldquo;Love2Shop Gift Card&rdquo; in the rewards screen of the app to choose your preferred reward. To cash out to PayPal, you&rsquo;ll need to enter the PayPal account email address where you want to receive the payment if this differs from the email you used when you signed up to YourVue.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapserewardsFour" aria-expanded="false" aria-controls="collapserewardsFour">How soon can I choose my Love2Shop Gift Card?</button> </h2>
                                        <div id="collapserewardsFour" className="accordion-collapse collapse" data-bs-parent-removed="#rewards">
                                            <div className="accordion-body">
                                                <p>When you choose to redeem your points for Love2Shop Gift Card, simply select the Gift Card value (available in multiples of &pound;5), click confirm, and YourVue exchanges your points for a Love2Shop e-Gift Card instantly. The Love2Shop store has over 30 branded Gift Cards to choose from, including favourites such as Amazon and Uber Eats. How long you spend browsing the Love2Shop store is entirely up to you!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapserewardsFive" aria-expanded="false" aria-controls="collapserewardsFive">How soon will I receive my payment to PayPal?</button> </h2>
                                        <div id="collapserewardsFive" className="accordion-collapse collapse" data-bs-parent-removed="#rewards">
                                            <div className="accordion-body">
                                                <p>When you click &ldquo;Cash out to PayPal&rdquo;, YourVue transfers your payment to PayPal instantly. To avoid delays with PayPal, please ensure your PayPal account is active and can receive payments.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapserewardsSix" aria-expanded="false" aria-controls="collapserewardsSix">Can I change my PayPal account?</button> </h2>
                                        <div id="collapserewardsSix" className="accordion-collapse collapse" data-bs-parent-removed="#rewards">
                                            <div className="accordion-body">
                                                <p>Yes, simply update your nominated PayPal account email in the YourVue app before cashing out your rewards. If you are aged 18 or over, you can sign up to create a free PayPal account on PayPal’s website.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapserewardsSeven" aria-expanded="false" aria-controls="collapserewardsSeven">I don’t have a PayPal account can I get Amazon vouchers or other rewards instead?</button> </h2>
                                        <div id="collapserewardsSeven" className="accordion-collapse collapse" data-bs-parent-removed="#rewards">
                                            <div className="accordion-body">
                                                <p>Yes, simply click &ldquo;Love2Shop Gift Card&rdquo; on the rewards screen to exchange your points for an e-Gift Card, then select your preferred Gift Card from the Love2Shop store. With over 30 top branded Gift Cards to choose from, including Amazon, you’re sure to find something you love in the Love2Shop store.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="faqgroup5" className="faq-group">
                                <h4>Privacy and Security</h4>
                                <p>How we protect your privacy and keep your data secure</p>
                                <div className="accordion accordion-flush faq-accordian" id="privacy">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrivacyOne" aria-expanded="false" aria-controls="collapsePrivacyOne">Can I sign in with Apple or Google?</button> </h2>
                                        <div id="collapsePrivacyOne" className="accordion-collapse collapse" data-bs-parent-removed="#privacy">
                                            <div className="accordion-body">
                                                <p>Yes, simply select this option when you first open the YourVue app to create your account.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrivacyTwo" aria-expanded="false" aria-controls="collapsePrivacyTwo">Is my information secure?</button> </h2>
                                        <div id="collapsePrivacyTwo" className="accordion-collapse collapse" data-bs-parent-removed="#privacy">
                                            <div className="accordion-body">
                                                <p>YourVue app is protected by your login credentials on your device. Please keep your login and password information safe, and do not share this information with anyone, including YourVue support team. To learn more about how we protect your privacy and the security of the data that you share with us, please refer to our <a href="privacypolicy">privacy policy</a> here.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrivacyThree" aria-expanded="false" aria-controls="collapsePrivacyThree">Can the companies identify me from my YourVue profile or survey answers?</button> </h2>
                                        <div id="collapsePrivacyThree" className="accordion-collapse collapse" data-bs-parent-removed="#privacy">
                                            <div className="accordion-body">
                                                <p>No. Quite simply, we don&rsquo;t share your individual profile information with any of our clients. YourVue uses your profile answers to show you surveys that are relevant to your overall demographic, such as your age group, the size of your household, your geographic region, or your occupation category. Survey responses are pseudonymised, which means that no-one could identify any individual person from the data.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrivacyFour" aria-expanded="false" aria-controls="collapsePrivacyFour">Why do some surveys ask for my contact details or other personal information? </button> </h2>
                                        <div id="collapsePrivacyFour" className="accordion-collapse collapse" data-bs-parent-removed="#privacy">
                                            <div className="accordion-body">
                                                <p>If a study needs specific personally identifiable information, for example, your full home postcode, you will be informed at the start of the survey of the reasons why this information is required, and how it will be used, prior to giving your consent. If decide not to participate, you can easily opt-out of these surveys with one click to decline the survey on the first page.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrivacyFive" aria-expanded="false" aria-controls="collapsePrivacyFive">I forgot my login, how do I reset my YourVue password?</button> </h2>
                                        <div id="collapsePrivacyFive" className="accordion-collapse collapse" data-bs-parent-removed="#privacy">
                                            <div className="accordion-body">
                                                <p>Open the YourVue app and request a password reset and you&rsquo;ll be sent an email to help you set a new password. Remember to open the email link on the same device where you have the YourVue app installed.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header"> <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrivacySix" aria-expanded="false" aria-controls="collapsePrivacySix">How do I unsubscribe from YourVue and delete my data? </button> </h2>
                                        <div id="collapsePrivacySix" className="accordion-collapse collapse" data-bs-parent-removed="#privacy">
                                            <div className="accordion-body">
                                                <p>You can unsubscribe from YourVue and delete your personal data at any time by clicking the &ldquo;Delete profile&rdquo; button on the profile page in the app. Please ensure you cash out any rewards of &pound;5 or over to PayPal or Love2Shop, before deleting your profile. If you have already deleted the YourVue app from your device, please email YourVue Support to unsubscribe and delete your account: <a href="mailto:support@your-vue.com">support@your-vue.com</a>. More information about deleting your profile data can be found <a href="datadeletion">here</a>.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

export default Faqs;
