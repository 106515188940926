import * as React from 'react';
import { Helmet } from 'react-helmet';

const Landing = () => {
    return (<>
        <section className="d-flex flex-column full-size-header hero-header justify-content-center overflow-hidden position-sticky section-fade-in" data-pg-name="Hero" >
            <canvas id="canvas" width="32px" height="32px" className="position-absolute"></canvas>
            <div className="container">
                <div className="hero-content justify-content-center justify-content-lg-between position-relative row z-3">
                    <div className="col-11 col-lg-6 col-md-8 d-flex flex-column justify-content-center pb-5 pb-md-0 text-white"><small className="d-block fade-in">Welcome to YourVue</small>
                        <h1 className="display-5 fade-in fw-bold main-header pb-4 underline-header">Personalised surveys on your mobile. Flexible by design&nbsp;<span className="text-dark">to suit your lifestyle</span></h1>
                        <p className="fade-in">Share your views and earn rewards anytime anywhere. Global influence in the palm of your hand. Cash out your rewards from just &pound;5.</p>
                    </div>
                    <div className="col-12 col-lg-5 col-md-4 d-md-flex d-none header-screen-col justify-content-center position-relative pt-5 pt-md-0">
                        <div id="header-lot" className="header-screen"></div>
                    </div>
                    <div className="col-1 d-lg-flex d-none header-screen-col justify-content-center justify-content-lg-end position-relative pt-5 pt-md-0">
                        <a href="#features" className="align-items-center d-flex fade-in flex-column justify-content-end link-light scroll-link text-decoration-none text-white">Scroll<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" className="mt-1">
                            <g id="scroll-arrow" transform="translate(-1165 -575)">
                                <g id="arrow">
                                    <line id="Line_11" data-name="Line 11" x2="13.547" transform="translate(1179.724 583) rotate(90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
                                    <line id="Line_12" data-name="Line 12" x2="5.009" y2="5.724" transform="translate(1185.448 591.538) rotate(90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
                                    <line id="Line_13" data-name="Line 13" y1="5.724" x2="5.009" transform="translate(1179.724 591.538) rotate(90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
                                </g>
                                <g id="circle" transform="translate(1165 575)" fill="none" stroke="#fff" strokeWidth="1">
                                    <circle cx="15" cy="15" r="15" stroke="none" />
                                    <circle cx="15" cy="15" r="14.5" fill="none" />
                                </g>
                            </g>
                        </svg></a>
                    </div>
                </div>
            </div>
        </section>
        <div className="main-content" >
            <section data-pg-name="Features" className="bg-white position-relative section-fade-in section-features z-2 z-5" data-pg-ia-scene='{"on":"desktop","s":"onEnter","l":[{"name":"Fade In Bits","t":".fade-in","a":{"l":[{"t":"","l":[{"t":"set","p":0,"d":0,"l":{"autoAlpha":0},"e":"Power1.easeOut"},{"t":"tween","p":0,"d":1,"s":0.2,"l":{"autoAlpha":1},"e":"Power1.easeOut"}]}]},"p":"time","on":"desktop"}],"o":"200px"}' data-pg-ia-apply="$.section-fade-in" id="features">
                <div className="container">
                    <div className="justify-content-center mb-4  pt-4 row">
                        <div className="col-11 col-lg-4 fade-in mb-5 mb-lg-0" data-pg-ia-hide="desktop" data-pg-ia-apply="$.fade-in">
                            <div className="border-0 brand-card card feature-card overflow-hidden rounded-5 text-white">
                                <div className="card-body  p-4 p-5 p-xl-5">
                                    <h4 className="card-title col-12 col-lg-9 col-md-11 col-xl-11 h2 mt-2 mt-lg-0">Personalised surveys  on-the-go</h4>
                                    <img alt="" src="assets/svg/sv-yv-personalised-screen.svg" className="card-screen position-absolute" />
                                </div>
                            </div>
                        </div>
                        <div className="col-11 col-lg-4 fade-in mb-5" data-pg-ia-hide="desktop">
                            <div className="border-0 brand-card card feature-card overflow-hidden rounded-5 text-white">
                                <div className="card-body  p-5">
                                    <h4 className="card-title col-12 col-md-11 col-xl-11 h2 mt-2 mt-lg-0">Cash rewards  paid to PayPal</h4>
                                    <img alt="" src="assets/svg/sv-yv-cash-out-screen.svg" className="card-screen position-absolute" />
                                    <img alt="" src="assets/svg/ww-yv-phone-overlay.svg" className="card-screen-over d-md-block d-none position-absolute" />
                                </div>
                            </div>
                        </div>
                        <div className="col-11 col-lg-4 fade-in mb-lg-0 " data-pg-ia-hide="desktop">
                            <div className="border-0 brand-card card feature-card overflow-hidden rounded-5 text-white">
                                <div className="card-body  p-4 p-5 p-xl-5">
                                    <h4 className="card-title col-12 col-md-11 col-xl-11 h2 mt-2 mt-lg-0">Be influential make YourVue count!</h4>
                                    <img alt="" src="assets/svg/sv-yv-welcome-screen.svg" className="card-screen position-absolute" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-pg-name="Why" className="bg-white overflow-hidden position-relative section-fade-in section-spacer z-2">
                <div className="container">
                    <div className="align-items-center justify-content-center row">
                        <div className="col-11 col-lg-5 fade-in offset-lg-1 order-2">
                            <h2 className="h6 mb-1 text-primary">YourVue</h2>
                            <p className=" display-6 fw-bold mb-4">Why YourVue?</p>
                            <p className="mb-4"><b>YourVue connects you with companies and charities who want your feedback.</b> Answer surveys exclusively through the app. Receive incentive points which you can <b>cash out via PayPal or choose a Love2Shop gift card</b> whenever you have over the &pound;5 minimum.</p>
                            <p className="mb-4"><b>YourVue membership is free </b>and the YourVue app is available to install free of charge from GooglePlay and AppStore.  The app provides high quality surveys and minimises screen outs on basic questions that you&rsquo;ve already answered.</p>
                        </div>
                        <div className="col-11 col-lg-5 fade-in mb-5 mb-lg-0 offset-lg-1 order-lg-1 position-relative">
                            <div className="ratio ratio-1x1 sq-image-holder">
                                <img alt="" src="assets/jpg/sv-yv-phone.jpg" className="left-right-image object-fit-cover rounded-5" />
                            </div>
                            <img alt="" src="assets/svg/ww-yv-phone-overlay.svg" className="phone-overlay" />
                        </div>
                    </div>
                </div>
            </section>
            <section data-pg-name="Trust" className="bg-white position-relative section-fade-in section-spacer z-2">
                <div className="container">
                    <div className="align-items-end justify-content-center row">
                        <div className="col-11 col-lg-7 fade-in">
                            <h2 className="h6 mb-2 text-primary">Reviews</h2>
                            <p className="display-6 fw-bold mb-0">Read what others are saying about YourVue</p>
                        </div>
                        <div className="align-items-center col-11 col-lg-5 d-flex fade-in flex-row justify-content-lg-end justify-content-start"><span className="display-6 fw-bold me-3 rating">4.8</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="148.283" height="25.664" viewBox="0 0 148.283 25.664">
                                <g id="stars" transform="translate(-991 -1451.845)">
                                    <path id="Path_3640" data-name="Path 3640" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(991 1451.845)" fill="#ff9502" />
                                    <path id="Path_3641" data-name="Path 3641" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1021.325 1451.845)" fill="#ff9502" />
                                    <path id="Path_3642" data-name="Path 3642" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1051.649 1451.845)" fill="#ff9502" />
                                    <path id="Path_3643" data-name="Path 3643" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1081.974 1451.845)" fill="#ff9502" />
                                    <path id="Path_3644" data-name="Path 3644" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1112.298 1451.845)" fill="#ff9502" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="justify-content-center row">
                        <div className="col-11 col-lg-12">
                            <div className="gy-5 gy-lg-0 mb-4 mt-5  row">
                                <div className="col-12 col-lg-3 col-md-6 fade-in">
                                    <div className="bg-light border-0 card h-100 review-card rounded-5">
                                        <div className="card-body  pb-4 pe-4 ps-4 pt-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="148.283" height="25.664" viewBox="0 0 148.283 25.664" className="mb-2">
                                                <g id="stars" transform="translate(-991 -1451.845)">
                                                    <path id="Path_3640" data-name="Path 3640" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(991 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3641" data-name="Path 3641" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1021.325 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3642" data-name="Path 3642" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1051.649 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3643" data-name="Path 3643" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1081.974 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3644" data-name="Path 3644" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1112.298 1451.845)" fill="#ff9502" className="text-black-50" />
                                                </g>
                                            </svg>
                                            <p className="review-person small text-light-emphasis">JennyL6 ,  02/12/2023</p>
                                            <h6 className="mb-0 small">The best</h6>
                                            <p className="mb-0 small">One of the best survey apps/sites I&rsquo;ve used. They pay out a reasonable amount for the time spent e.g a one minute survey is 10 points which soon adds up. The most...</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 col-md-6 fade-in">
                                    <div className="bg-light border-0 card h-100 review-card rounded-5">
                                        <div className="card-body  pb-4 pe-4 ps-4 pt-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="148.283" height="25.664" viewBox="0 0 148.283 25.664" className="mb-2">
                                                <g id="stars" transform="translate(-991 -1451.845)">
                                                    <path id="Path_3640" data-name="Path 3640" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(991 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3641" data-name="Path 3641" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1021.325 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3642" data-name="Path 3642" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1051.649 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3643" data-name="Path 3643" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1081.974 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3644" data-name="Path 3644" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1112.298 1451.845)" fill="#ff9502" className="text-black-50" />
                                                </g>
                                            </svg>
                                            <p className="review-person small text-light-emphasis">Another Devon Maid , 30/11/2023</p>
                                            <h6 className="mb-0 small">Great survey site</h6>
                                            <p className="mb-0 small">I use several survey sites/apps and the others have a much higher threshold before cashing out. I don&rsquo;t mind that as it&rsquo;s a decent amount when I receive it but YourVue is great in...</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 col-md-6 fade-in">
                                    <div className="bg-light border-0 card h-100 review-card rounded-5">
                                        <div className="card-body  pb-4 pe-4 ps-4 pt-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="148.283" height="25.664" viewBox="0 0 148.283 25.664" className="mb-2">
                                                <g id="stars" transform="translate(-991 -1451.845)">
                                                    <path id="Path_3640" data-name="Path 3640" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(991 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3641" data-name="Path 3641" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1021.325 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3642" data-name="Path 3642" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1051.649 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3643" data-name="Path 3643" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1081.974 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3644" data-name="Path 3644" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1112.298 1451.845)" fill="currentColor" className="text-black-50" />
                                                </g>
                                            </svg>
                                            <p className="review-person small text-light-emphasis">Lounage ,  13/12/2023</p>
                                            <h6 className="mb-0 small">Good side hustle</h6>
                                            <p className="mb-0 small">I was able to cash out yesterday to my PayPal which was very fast. The more points you get then the more money you can cash out. I would recommend people to use to...</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 col-md-6 fade-in">
                                    <div className="bg-light border-0 card h-100 review-card rounded-5">
                                        <div className="card-body  pb-4 pe-4 ps-4 pt-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="148.283" height="25.664" viewBox="0 0 148.283 25.664" className="mb-2">
                                                <g id="stars" transform="translate(-991 -1451.845)">
                                                    <path id="Path_3640" data-name="Path 3640" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(991 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3641" data-name="Path 3641" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1021.325 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3642" data-name="Path 3642" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1051.649 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3643" data-name="Path 3643" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1081.974 1451.845)" fill="#ff9502" />
                                                    <path id="Path_3644" data-name="Path 3644" d="M13.492,0,10.307,9.8H0l8.339,6.058-3.185,9.8,8.339-6.058,8.338,6.058-3.185-9.8L26.984,9.8H16.677Z" transform="translate(1112.298 1451.845)" fill="#ff9502" className="text-black-50" />
                                                </g>
                                            </svg>
                                            <p className="review-person small text-light-emphasis">Zeropoiu ,  01/01/2024</p>
                                            <h6 className="mb-0 small">Multiple surveys</h6>
                                            <p className="mb-0 small">A variety of topics that are quick and simple to complete although there are not always a full range of responses to apply.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-pg-name="Trust" className="bg-white position-relative section-fade-in section-spacer z-2">
                <div className="container">
                    <div className="align-items-center justify-content-center row">
                        <div className="col-11 col-lg-5 fade-in offset-lg-1 order-2 order-lg-1" data-pg-ia-hide="desktop" data-pg-ia-apply="$.fade-in">
                            <h2 className="h6 mb-1 text-primary">YourVue</h2>
                            <p className=" display-6 fw-bold mb-4">Personalised Surveys
                                on-the-go</p>
                            <p className="mb-4">YourVue Surveys bridge the gap between you and the latest leading research. Our platform provides a unique opportunity to influence global brands, charities, government, and financial institutions with your perspective. Our research findings are often quoted in the media, making your participation impactful.</p>
                            <p className="mb-4">We value your time, hence we offer quick, engaging surveys personalised to your profile with a very low screen-out rate. All surveys list the topic, average completion time, and reward points upfront, so you know what to expect before you begin. Life happens and need a break? You can pause the survey and return later, provided the survey is still open. YourVue surveys, where your opinion shapes the world.</p>
                        </div>
                        <div className="bg-transparent col-11 col-lg-5 fade-in mb-5 mb-lg-0 offset-lg-1 order-lg-2">
                            <div className="ratio ratio-1x1 sq-image-holder">
                                <img alt="" src="assets/jpg/sv-yv-glasses-bus.jpg" className="left-right-image object-fit-cover rounded-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-pg-name="Anytime" className="bg-white position-relative section-spacer z-2" data-pg-ia-scene='{"l":[{"name":"Background Position","t":".col-11 #gt# div","a":{"l":[{"t":"","l":[{"t":"set","p":0,"d":0,"l":{"backgroundPositionX":"500%"}},{"t":"tween","p":0,"d":1.97,"l":{"backgroundPositionX":"130%"},"e":"Power4.easeInOut"}]},{"t":".fade-in","l":[{"t":"set","p":0,"d":0,"l":{"autoAlpha":0}},{"t":"tween","p":0.84,"d":1.39,"l":{"autoAlpha":1}}]}]},"on":"desktop"}],"on":"desktop"}'>
                <div className="container">
                    <div className="align-items-center justify-content-center row">
                        <div className="col-11 col-lg">
                            <div className="big-card border-0 card pb-5 pt-5 rounded-5 text-bg-dark">
                                <div className="align-items-center g-0 h-100 justify-content-center justify-content-lg-start row">
                                    <div className="col-10 col-xl-5 fade-in offset-lg-1">
                                        <div className="card-body">
                                            <h2 className="h6 mb-1 text-primary">How it Works</h2>
                                            <p className="display-6 fw-bold mb-4 underline-header underline-hedaer-white">Getting started with
                                                YourVue <span>is easy</span></p>
                                            <p className="mb-4">Simply download the app to your device and sign up to create your personal profile. Once set up, you’ll receive convenient survey notifications directly on your mobile, enabling you to complete surveys on-the-go, whenever and wherever suits you best.</p>
                                            <p className="mb-4">Your participation is rewarded with points, and once you’ve accumulated £5 worth, you can redeem a Gift Card from Love2Shop, or cash out directly to your PayPal account. The best part? You’re free to spend your earnings on whatever you fancy! YourVue, where your view matters and rewards you.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-pg-name="Anytime" className="bg-white position-relative section-fade-in section-spacer z-2">
                <div className="container">
                    <div className="align-items-center justify-content-center row">
                        <div className="col-11 col-lg-5 fade-in offset-lg-1 order-2">
                            <h2 className="h6 mb-1 mt-5 mt-lg-0 text-primary">YourVue</h2>
                            <p className=" display-6 fw-bold mb-4">Reap Your Rewards</p>
                            <p className="mb-4">With YourVue Rewards, each survey you complete earns you points that translate into real monetary value. Your balance, always displayed in &pound;&rsquo;s, also indicates the number of points needed to reach your next cash out.</p>
                            <p className="mb-4">As soon as your balance reaches &pound;5, you can claim a Love2Shop Gift Card or cash out to your PayPal account in an instant. Earning rewards has never been so straightforward and satisfying! Spend your rewards on whatever you wish, a treat for yourself or someone close to you.</p>
                        </div>
                        <div className="col-11 col-lg-5 fade-in  offset-lg-1 order-lg-1">
                            <div className="ratio ratio-1x1 sq-image-holder">
                                <img alt="" src="assets/jpg/sv-yv-couple-laptop.jpg" className="left-right-image object-fit-cover rounded-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Helmet>
            <script src="https://code.jquery.com/jquery-3.7.1.slim.min.js" integrity="sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8=" crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.6.5/lottie.min.js"></script>
            <script src="assets/js/yv.js"></script>
        </Helmet>
    </>
    );
}

export default Landing;