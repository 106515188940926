import * as React from 'react';
import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import TermsAndConditions from './TermsAndConditions';
import Support from './Support';
import DataDeletion from './DataDeletion';
import Landing from './Landing';
import PrivacyPolicy from './PrivacyPolicy';
import EmailVerified from './EmailVerified';
import Unsubscribe from './Unsubscribe';
import LandingCoReg from './LandingCoReg';
import OpcLink from './OpcLink';
import Faqs from './Faqs';
import { Helmet } from 'react-helmet';

const Routing = () => <Router>
    <Routes>
        <Route path="/coreg/:sid" element={<LandingCoReg />} />
        <Route path="/" element={<Main />}>
            <Route path="" element={<Landing />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="support" element={<Support />} />
            <Route path="emailVerified/*" element={<EmailVerified />} />
            <Route path="termsandconditionsandprivacypolicy" element={<TermsAndConditions />} />
            <Route path="termsandconditions" element={<TermsAndConditions />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="datadeletion" element={<DataDeletion />} />
            <Route path="unsubscribe/:id" element={<Unsubscribe />} />
            <Route path="opc" element={<OpcLink />} />
            <Route path="*" element={<div>Not found</div>} />
        </Route>
    </Routes>
</Router>;

const Header = () => {
    return (<>
        {window.location.pathname === '/' ?
            <nav className="main-nav navbar w-100 z-1" style={{ position: 'fixed', top: '0px', left: '0px' }}>
                <div className="container "> <a className="navbar-brand" href="/" ><img alt="" src="assets/svg/sv-yv-footer-logo.svg" className="hedaer-logo" /><span className="visually-hidden">YourVue</span></a>
                    <ul className="d-md-block d-none me-3 me-md-4 ms-auto navbar-nav">
                        <li className="nav-item"> <a className="fw-bold link-light nav-link" href="faqs">FAQs</a>
                        </li>
                    </ul>
                </div>
            </nav>
            :
            <section className="d-flex flex-column justify-content-center overflow-hidden" data-pg-name="Hero">
                <nav className="main-nav navbar w-100 z-1">
                    <div className="container "> <a className="navbar-brand" href="/"><img alt="" src="assets/svg/sv-yv-header-colour-logo.svg" className="hedaer-logo" /><span className="visually-hidden">YourVue</span></a>
                        <ul className="d-md-block d-none me-3 me-md-4 ms-auto navbar-nav">
                            <li className="nav-item"> <a className="fw-bold nav-link" href="faqs">FAQs</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </section>
        }
    </>)
}

const Footer = () => {
    return (<>
        <footer className="bg-white pb-5 position-relative z-2">
            <div className="container pe-4 ps-4">
                <div className="row">
                    <div className="col-md-4">
                        <a className="navbar-brand" href="/"><img alt="" src="assets/svg/sv-yv-header-colour-logo.svg" /><span className="visually-hidden">YourVue</span></a>
                    </div>
                </div>
                <div className="mt-4 row">
                    <div className="col">
                        <p className="mb-0 text-muted">&copy;YourVue 2024</p>
                    </div>
                    <div className="col-12 col-md-9 mt-5 mt-md-0 text-md-end">
                        <ul className="d-flex flex-column flex-md-row justify-content-end list-unstyled mt-2 mt-md-0">
                            <li className="mb-2 mb-md-0 me-3">
                                <a href="privacypolicy" className="text-muted underline-link">Privacy Policy</a>
                            </li>
                            <li className="mb-2 mb-md-0 me-3 ms-md-4"><a href="termsandconditions" className="text-muted underline-link">Terms & Conditions</a>
                            </li>
                            <li className="mb-2 mb-md-0 me-3 ms-md-4"><a href="datadeletion" className="text-muted underline-link">Data Deletion</a>
                            </li>
                            <li className="mb-2 mb-md-0 ms-md-4 "><a href="support" className="text-muted underline-link">Support</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <Helmet>
            <script src="assets/js/popper.min.js"></script>
            <script src="bootstrap/js/bootstrap.min.js"></script>
            <script src="pgia/lib/pgia.js"></script>
        </Helmet>
    </>)
}

const Main = () => {
    return (<>
        <Header />
        <main><Outlet /></main>
        <Footer />
    </>)
}

const App = () => (
    <Routing />
);


export default App;